import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';
import Header from '../common/Header'
import Footer from '../common/Footer'
const Home = (props) => {
    const { alert, showAlert } = useContext(AlertContext);
    var token = localStorage.getItem("token")
    const { alertMessage, isAuth,otpSent,newUser, sendEmailContext, loginFailed,  sendVerificationOtp, otpVerification , user} = useContext(AuthContext);
 
    const [phone, setPhone]=useState("")  
    const [user_name, setName]=useState("")  
    const [user_email, setEmail]=useState("")  
    const [otp, setOtp] = useState(['', '', '', '']); // Array to store OTP digits
    const otpRefs = useRef([...Array(4)].map(() => React.createRef())); // Refs for OTP input boxes
    const handleInputChange = (index, value) => {
        if (value.match(/^\d+$/) && index < otp.length) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
      
            if (index < otp.length - 1 && value !== '') {
              otpRefs.current[index + 1].current.focus();
            }
        } else if (value === '' && index > 0) {
            // Handle backspace to go to the previous input box
            otpRefs.current[index - 1].current.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
        // Move to the previous input box on backspace
        otpRefs.current[index - 1].current.focus();
        }else if (e.key === 'Backspace' && index >= 0) {
            // Remove digit on backspace
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };
    useEffect(() => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
        });
        if (isAuth) {
            
            Toast.fire({
                icon: "success",
                title: "Successfully Logged In"
            });

            props.history.push('/schedule/new');
        }
        if(otpSent==true){
            Toast.fire({
                icon: "success",
                title: "OTP Sent To Your Registered E-Mail"
            });
        }
        if(loginFailed==true){
            
            Toast.fire({
                icon: "error",
                title: "Invalid OTP Please try again"
            });
        }
        if (alertMessage) {
            showAlert(alertMessage.msg, alertMessage.category);
        }
        // eslint-disable-next-line
    }, [alertMessage, isAuth, props.history, loginFailed,otpSent]);

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = loginData;

    const sendOtp = ()=>{
        // setOtpSent(true)
        if(phone.length!=10){
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: "Mobile Number is not correct"
            });
        }else{
            sendVerificationOtp({phone_no:phone});
        }
    }
    const sendEmail= () => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
        });
        if(user_name==""){
            Toast.fire({
                icon: "error",
                title: "Please enter your name"
            });
        }
        if(user_email==""){
            Toast.fire({
                icon: "error",
                title: "Please enter your Email"
            });
        }

        sendEmailContext({
            usermobile:phone,
            email:user_email,
            name: user_name
        });

    }
    const verifyOtp= ()=>{
        console.log(phone)
        const enteredOtp = otp.join('');
        if (enteredOtp.length === 4) {
            otpVerification({
                mobile:phone,
                otp:enteredOtp,
                useremail:user_email,
                name: user_name
            })
        } else if (enteredOtp.length > 4) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: "Entered OTP is too long"
            });
            console.log('Entered OTP is too long');
        } else if (enteredOtp.length > 0) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: "Entered OTP is too short"
            });
        }
        
    }
    const scheduleNewPickup =()=>{
        props.history.push('/schedule/new');
    }   
    const checkMyPickup =()=>{
        props.history.push('/my/pickups');
    }
    const logout = ()=>{
        
    }
    const logoutApplication = () =>{
        localStorage.removeItem("token")
        props.history.push('/');
    }
    return (
        <>
        <Header/>
            <section class="banner">
                <div class="container-fluid">
                    <div class="row">
                    {(token==null)?(
                       <div class="col-xs-6 col-md-5 col-lg-4">
                            <form class="left-form">
                                
                                
                                { (otpSent==false) ? (
                                    <div>
                                        {otpSent==false && newUser==true ? (
                                            <div>
                                                <p>Name</p>
                                                <div class="phone-icon-wrap d-flex align-items-center">
                                                    <input class="form-control" value={user_name} onChange={e => setName(e.target.value)} placeholder="Please enter your name" type="text" aria-label="Search"/>
                                                </div>
                                                <p>Email</p>
                                                <div class="phone-icon-wrap d-flex align-items-center">
                                                    <input class="form-control" value={user_email} onChange={e => setEmail(e.target.value)} placeholder="Please enter your email" type="text" aria-label="Search"/>
                                                </div>
                                                <div>
                                                
                                                <input type='button' class="btn btn-nxt mt-2" onClick={sendEmail} value="Next"/>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <p>BOOK A PICKUP</p>
                                                <div class="phone-icon-wrap d-flex align-items-center">
                                                    <div>+91</div>
                                                    <input class="form-control inputsearch" value={phone} onChange={e => setPhone(e.target.value)} type="text" aria-label="Search"/>
                                                </div>
                                                <input type='button' class="btn btn-nxt mt-2" onClick={sendOtp} value="Next"/>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                    <div class="input-field">
                                       
                                        {otp.map((digit, index) => (
                                            <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            ref={otpRefs.current[index]}
                                            />
                                        ))}
                                    </div>
                                    <input type='button' class="btn btn-nxt mt-2" onClick={verifyOtp} value="Next"/>
                                    </div>
                                )}
                                
                                
                                
                                <p class="mt-2">Facing Problems? Call us at <span class="text-color">+91 7668873812</span></p>
                            </form>
                        </div> 
                    ):(
                        <div class="col-xs-6 col-md-5 col-lg-4">
                            <div class="login_form_open">

                            <div class="local_login_form first_login_form">
                            
                            
                            <div class="old_user_div">
                                <h4 class="local_login_title">Welcome to Scrapify!<button class="lgout btn btn-default" onClick={logoutApplication}>Logout</button></h4>
                                
                                <div class="old_user_img">
                                
                                    <img src="https://ikp.edgekit.net/y8s2vhk66ef/Main_Page_Illustration_e9Jz4TCH361.png?updatedAt=1628127523534" alt=""/>
                                    
                                </div>
                                <div class="button_div">
                                    <a class="newpickups local_next_button old_user_button pickupBtn md button button-solid ion-activatable ion-focusable" onClick={scheduleNewPickup}>Schedule a pickup</a>
                                    <a class="mypickups mt-3 local_next_button old_user_button pickupBtn md button button-solid ion-activatable ion-focusable" onClick={checkMyPickup}>Check my pickups</a>                                    
                                </div>
                                
                            </div>
                            </div>
                            </div>
                        </div>
                    )}
                    
                    
                    <div class="col-xs-6 col-md-7 col-lg-8 text-right ">
                        <h2 class="headingbanner"><span>Sell Your Recyclables Online with The Scrapify: 
                        Revolutionizing Recycling for a Sustainable Future </span>The scrapify!
                        </h2>
                        <p class="text-right">Paper-Plastics-Metals-Appliances<br/>
                            You can Also Download the <span>Scrapify App</span>
                        </p>
                        <img src="assets/images/banner-img.jpg"/>
                    </div>
                    </div>
                    
                </div>
            </section>
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title">How it works</h2>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-4">
                        <div class="card-wrap">
                            <h3 class="heading2">Schedule a pickup</h3>
                            <img src="assets/images/schedule.png"/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-wrap">
                            <h3 class="heading2">Pickup at your address</h3>
                            <img src="assets/images/pickup.png"/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-wrap">
                            <h3 class="heading2">Receive payment</h3>
                            <img src="assets/images/payment.png"/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section class="section bg-green">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="" style={{'font-size':"35px"}}>Why Us</h2>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-3">
                        <div class="card-wrap2 d-flex">
                            <div class="img-left"><img src="assets/images/best-rates.png"/></div>
                            <div class="cont-right">
                                <h3 class="heading2">Best Rates</h3>
                                <p>Turn your unwanted materials into a lucrative income stream while promoting sustainability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-wrap2 d-flex">
                            <div class="img-left"><img src="assets/images/convenience.png"/></div>
                            <div class="cont-right">
                                <h3 class="heading2">Convenience</h3>
                                <p>Sell your recyclables from the comfort of your own home, without the hassle of traditional recycling centers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-wrap2 d-flex">
                            <div class="img-left"><img src="assets/images/trust.png"/></div>
                            <div class="cont-right">
                                <h3 class="heading2">Trust</h3>
                                <p>With The Scrapify, transparency and fairness are paramount, ensuring confidence for both sellers and buyers.                 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-wrap2 d-flex">
                            <div class="img-left"><img src="assets/images/eco.png"/></div>
                            <div class="cont-right">
                                <h3 class="heading2">Eco-friendly</h3>
                                <p>By recycling with The Scrapify, you're helping to reduce waste and conserve valuable resources for future generations.
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section class="section needs-sec">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title text-green text-left">Catering to Diverse Needs: Tailored Solutions for Homes and Businesses.
                        </h2>
                    </div>
                    </div>
                    <div class="row align-items-center">
                    <div class="col-md-5">
                        <img src="assets/images/households.png"/>
                    </div>
                    <div class="col-md-7">
                        <h2 class="site-title mt-3">For Households</h2>
                        <ul class="list">
                            <li>
                                Simplify Recycling: Effortlessly manage your recyclables.
                            </li>
                            <li>
                                Precision at Scale: Accurate digital weighing for reliable recycling.
                            </li>
                            <li>
                                Your Safety, Our Priority: Trained and Verified Staff Always.
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div class="row align-items-center">
                    <div class="col-md-7">
                        <h2 class="site-title mt-3">For Businesses</h2>
                        <ul class="list">
                            <li>
                                Formalize Transactions: Secure Payments and Documented Recycling Process.
                            </li>
                            <li>
                                Transparency in Action: Comprehensive Sustainability Reporting for Accountability.
                            </li>
                            <li>
                                Competitive Rates: Ensuring Value for Your Recyclable Materials.
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5 text-right">
                        <img src="assets/images/banner-img.jpg"/>
                    </div>
                    </div>
                </div>
            </section>
            
            <section class="section">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="site-title">Testimonials</h2>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-3">
                        <div class="testimonial-wrap">
                            <h3><span>A</span>Ananya Shee</h3>
                            <div class="content-testi">
                                Exceptional service! Quick response and seamless process for scrap collection from home. Their team was punctual, professional, and courteous. Hassle-free transaction and fair pricing. Highly recommended for anyone looking to declutter while contributing to sustainability. A top-notch online scrap solution for convenient home pickups.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="testimonial-wrap">
                            <h3><span>M</span>Mahesh Chauhan</h3>
                            <div class="content-testi">
                                Positive: Professionalism, Punctuality, Quality, Responsiveness, Value
Best services for the waste management.Doing great work in dehradun city.
Services: House & garage cleanouts, Mattress removal & disposal, Appliance removal & disposal, Waste recycling, Junk removal, Electronic waste removal & disposal, Construction debris removal, Donated goods pickup, Trash removal & disposal, Furniture removal
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="testimonial-wrap">
                            <h3><span>N</span>Nimish Sharma</h3>
                            <div class="content-testi">
                                Good service! Pickup was prompt and their staff was professional.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="testimonial-wrap">
                            <h3><span>L</span>Lokesh Tiwari</h3>
                            <div class="content-testi">
                                Best services and professional members
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </section>
            
        <Footer/>
        </>
    );
};

export default Home;
