import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';

const Footer = (props) => {
    const { alert, showAlert } = useContext(AlertContext);

    const { alertMessage, isAuth, logInUser } = useContext(AuthContext);

    useEffect(() => {
        if (isAuth) {
            //props.history.push('/projects');
        }
        if (alertMessage) {
            showAlert(alertMessage.msg, alertMessage.category);
        }
        // eslint-disable-next-line
    }, [alertMessage, isAuth, props.history]);
    
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = loginData;

    const updateState = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (email.trim() === '' || password.trim() === '') {
            showAlert('All fields are required', 'alert-error');
        }
        
        logInUser({ email, password });
    };
    return (
        <>
            <footer Class="main_footer wow fadeInUp section">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-9 contactus">
                            <h2>Contact Us</h2>
                            <p><i class="fa fa-phone-square" aria-hidden="true"></i>+91 7668873812,  +91 7895129395</p>
                            <p><i class="fa fa-envelope" aria-hidden="true"></i>    thescrapify@gmail.com</p>
                            <p class="mt-4"><strong>Customer Support available</strong></p>
                            <p>from 9am - 6pm</p>
                            <h2 class="mt-4">Address:</h2>
                            <p>
                                72VF+Q8X, Vidya Vihar Phase 2,<br/>
                                Brahmanwala, Dehradun,Uttarakhand<br/>
                                248121,India
                            </p>
                        </div>
                        <div class="col-md-3 footer-right">
                            <a href="#">
                            <img src="assets/images/logo-white.png" width="50%"/>
                            </a>
                            <p class="mt-5">Download the The scrapify App</p>
                            <a href="#">
                            <img src="assets/images/google-play-button.png" width="50%" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
