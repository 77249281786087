import React from 'react';
import Login from './components/auth/Login';
import Home from './components/home/Home';
import ScrapList from './components/home/ScrapList';
import Register from './components/auth/Register';
import Projects from './components/projects/Projects';
import OrderSchedule from './components/orderSchedule/OrderSchedule';
import MyPickups from './components/myPickups/MyPickups';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProjectState from './context/projects/ProjectState';
import TaskState from './context/tasks/taskState';
import AlertState from './context/alerts/alertState';
import AuthState from './context/auth/authState';
import SchedulePickupsState from './context/schedulePickups/SchedulePickupsState';
import authToken from './config/token';
import PrivateRoute from './components/privateRoute/PrivateRoute';
const token = localStorage.getItem('token');
if (token) {
    authToken(token);
}
function App() {
    return (
        <SchedulePickupsState>
            <ProjectState>
                <TaskState>
                    <AlertState>
                        <AuthState>
                            <Router>
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/register" component={Register} />
                                    <Route path="/logout" component={Register} />
                                    <Route path="/scrap-rates" component={ScrapList} />
                                    <Route path="/admin-login" component={Login} />
                                    <PrivateRoute path="/projects" component={Projects} />
                                    <PrivateRoute path="/my/pickups" component={MyPickups} />
                                    <PrivateRoute path="/schedule/new" component={OrderSchedule} />
                                </Switch>
                            </Router>
                        </AuthState>
                    </AlertState>
                </TaskState>
            </ProjectState>
        </SchedulePickupsState>
    );
}

export default App;
