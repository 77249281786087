import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AlertContext from '../../context/alerts/alertContext';
import AuthContext from '../../context/auth/authContext';

const Header = (props) => {
    const { alert, showAlert } = useContext(AlertContext);

    const { alertMessage, isAuth, logInUser } = useContext(AuthContext);
    var token = localStorage.getItem("token")
    useEffect(() => {
        // console.log(isAuth)
        if (token==null) {
            //props.history.push('/projects');
            //props.history.push('/');
        }
        if (alertMessage) {
            showAlert(alertMessage.msg, alertMessage.category);
        }
        // eslint-disable-next-line
    }, [alertMessage, isAuth, props.history]);

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = loginData;

    const updateState = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };
    const logoutApplication = () =>{
        localStorage.removeItem("token")
        props.history.push('/');
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (email.trim() === '' || password.trim() === '') {
            showAlert('All fields are required', 'alert-error');
        }
        
        logInUser({ email, password });
    };
    const scrollDown = () => {
        window.scrollBy(0, 1100);
    }
    return (
        <>
            <header class="main_header">
                <div class="container-fluid">
                    <div class="row align-items-center">
                    <div class="col-lg-2 col-sm-3 col-xs-6 col-5">
                        <div class="logo">
                            <a href="/">
                            <img class="img-responsive light_logo" src="/assets/images/logo.jpg" alt="logo"/>
                            <img class="img-responsive dark_logo" src="/assets/images/logo.jpg" alt="logo"/>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-10 col-sm-9 col-xs-6 col-7">
                        <div class="main_nav ml-auto">
                        
                            <nav id="primary_navigation" class="site_navigation">
                            <div class="navbtn ml-2">
                                <form class="form-inline">
                                    <input class="form-control inputsearch" type="search" placeholder="Search" aria-label="Search"/>
                                    <button class="btn-search p-0" type="submit">
                                    <img src="/assets/images/searchbg.jpg" width="30px"/>
                                    </button>
                                </form>
                                </div>
                                <div class="main_menu">
                                <a href="javascript:void(0);" class="close_menu">
                                    <i class="fa fa-times"></i>
                                </a>
                                
                                <ul class="nav main_menu_list justify-content-end">
                                    <li class="menu-item page-active">
                                        <Link to={'/'} class="active">Home</Link>
                                    </li>
                                    <li class="menu-item">
                                    <Link to={'/#why-us'} onClick={scrollDown} >Why Us?</Link>
                                    </li>
                                    <li class="menu-item">
                                    <Link to={'/scrap-rates'} >Scrap Rates </Link>
                                    </li>
                                    {token && (
                                        <li class="menu-item">
                                            <Link to={'/my/pickups'}>My Pickups </Link>
                                        </li>
                                    )}

                                    {token && (
                                        <li class="menu-item">
                                            <Link to={"/schedule/new"}>Schedule New </Link>
                                        </li>
                                    )}
                                    {token && (
                                        <li class="menu-item">
                                            <Link to={'/'} onClick={logoutApplication}>Logout </Link>
                                        </li>
                                    )}

                                </ul>
                                </div>
                                
                                <a class="menu-bar mobile-navigation hamburger" href="javascript:void(0)">
                                    <span class="menu-btn-icon">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </a>
                            </nav>
                        
                        </div>
                    </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
